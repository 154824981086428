import { Select, SelectButton, SelectList, SelectProps } from '@saas-ui/react'
import React, { useMemo } from 'react'
import { datasourceLabel } from '../../pages/environments/helper'
import {
  Datasource,
  Environment,
} from '@app/features/core/hooks/use-current-user'

type EnvironmentDatasourceSelectProps = SelectProps & {
  datasources: Datasource[]
  selectedDatasource: Datasource | null
  onSelectedDatasourceChange: (datasource: Datasource | null) => void
}

export const EnvironmentDatasourceSelect: React.FC<
  EnvironmentDatasourceSelectProps
> = ({
  datasources,
  selectedDatasource,
  onSelectedDatasourceChange,
  ...props
}) => {
  const onChangeCallback = React.useCallback(
    (value: string | null) => {
      const selected = datasources.find((ds) => ds.id.toString() === value)
      onSelectedDatasourceChange(selected ?? null)
    },
    [datasources, onSelectedDatasourceChange],
  )

  const datasourceOpts = useMemo(() => {
    return datasources.map((ds) => ({
      label: datasourceLabel(ds),
      value: ds.id.toString(),
    }))
  }, [datasources])

  return (
    <Select
      autoFocus
      value={selectedDatasource?.id.toString()}
      //defaultValue={datasources[0]?.id}
      placeholder={'Select a datasource'}
      size="sm"
      options={datasourceOpts}
      onChange={(value) => {
        onChangeCallback(value as string)
      }}
      {...props}
    >
      <SelectButton />
      <SelectList />
    </Select>
  )
}

export type MinimalEnvironment = Pick<
  Environment,
  'id' | 'name' | 'datasources' | 'slug'
>

type EnvironmentSelectProps = SelectProps & {
  environments: MinimalEnvironment[]
  selectedEnvironment: MinimalEnvironment | null
  onSelectedEnvironmentChange: (environment: MinimalEnvironment | null) => void
}

export const EnvironmentSelect: React.FC<EnvironmentSelectProps> = ({
  environments,
  selectedEnvironment,
  onSelectedEnvironmentChange,
  ...props
}) => {
  const onChangeCallback = React.useCallback(
    (value: string | null) => {
      const selected = environments.find((e) => e.id.toString() === value)
      onSelectedEnvironmentChange(selected ?? null)
    },
    [environments, onSelectedEnvironmentChange],
  )

  const environmentOptions = useMemo(() => {
    return environments.map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }))
  }, [environments])

  return (
    <Select
      value={selectedEnvironment?.id.toString()}
      //defaultValue={datasources[0]?.id}
      placeholder={'Select an environment'}
      size="sm"
      options={environmentOptions}
      onChange={(value) => {
        onChangeCallback(value as string)
      }}
      {...props}
    >
      <SelectButton />
      <SelectList zIndex={4} />
    </Select>
  )
}
