import { PostgresTable, TableCluster, MutationOrder } from '@api/client'
import { useMemo } from 'react'
import {
  Card,
  HStack,
  ListItem,
  OrderedList,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  Heading,
  TabPanels,
  Tabs,
  Tag,
  VStack,
  Text,
  Code,
} from '@chakra-ui/react'
import { MetricsCard } from '@app/features/tenants/components/metrics/metrics-card'
import { Section, SectionBody, SectionHeader } from '@saas-ui-pro/react'

export type TableClustersProps = {
  tableClusters?: TableCluster[]
}

const tabListProps: TabListProps = {
  overflow: 'hidden',
  borderTopRadius: 'md',
  display: 'flex',
  flexWrap: 'wrap',
}

const tableName = (pt: PostgresTable) => {
  if (pt.schema === 'public') return pt.name
  return `${pt.schema}.${pt.name}`
}

export type TableClusterWithMutationOrderProps = {
  tableCluster: TableCluster
}

const mutationOrderTypeToSqlPrefix = (type: string) => {
  switch (type) {
    case 'insert':
      return 'INSERT INTO '
    case 'update':
      return 'UPDATE '
    default:
      return 'UNKNOWN '
  }
}

const MutationOrdersByType: React.FC<{
  mutationOrders: MutationOrder[]
}> = ({ mutationOrders }) => {
  return (
    <>
      <Heading size="sm" mb={2}>
        Mutation Ordering
      </Heading>
      <OrderedList>
        {mutationOrders.map(
          ({ table, type, columns, returningColumns }, index) => {
            return (
              <ListItem key={index} mb={2}>
                <Code>{`${mutationOrderTypeToSqlPrefix(type)} ${tableName(table)}`}</Code>
                <Code>
                  {type == 'update'
                    ? `SET ${columns.join(', ')}`
                    : `(${columns.join(', ')})`}
                </Code>
                {returningColumns.length > 0 && (
                  <Code>RETURNING ({returningColumns.join(', ')})</Code>
                )}
              </ListItem>
            )
          },
        )}
      </OrderedList>
    </>
  )
}

const TableClusterWithMutationOrder: React.FC<{
  tableCluster: TableCluster
}> = ({ tableCluster }) => {
  if (tableCluster.tables.length <= 1)
    return (
      <Section variant="annotated">
        <SectionHeader title={tableName(tableCluster.tables[0])} />
        <SectionBody>
          <MutationOrdersByType mutationOrders={tableCluster.mutationOrders} />
        </SectionBody>
      </Section>
    )

  return (
    <Section variant="annotated">
      <SectionHeader title={`${tableCluster.tables.length} tables`} />
      <SectionBody>
        <MutationOrdersByType mutationOrders={tableCluster.mutationOrders} />
      </SectionBody>
    </Section>
  )
}

export const TableClusters = ({ tableClusters }: TableClustersProps) => {
  const loners = useMemo(() => {
    if (!tableClusters) return []
    return tableClusters.filter((tc) => tc.tables.length <= 1)
  }, [tableClusters])

  const nonLoners = useMemo(() => {
    if (!tableClusters) return []
    return tableClusters
      .filter((tc) => tc.tables.length > 1)
      .sort((a, b) => {
        return a.tables.length - b.tables.length
      })
  }, [tableClusters])

  return (
    <MetricsCard title="Table clusters" mb={10}>
      <Tabs>
        <TabList {...tabListProps}>
          {nonLoners.map((tc) => (
            <Tab key={tc.id}>{tc.tables.length} tables</Tab>
          ))}
          <Tab>
            <HStack alignItems="center">
              <Text>Loners</Text>
              <Tag>{loners.length}</Tag>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          {nonLoners.map((tc) => (
            <TabPanel key={tc.id}>
              <TableClusterWithMutationOrder key={tc.id} tableCluster={tc} />
            </TabPanel>
          ))}
          <TabPanel>
            {loners.map((tc) => (
              <TableClusterWithMutationOrder key={tc.id} tableCluster={tc} />
            ))}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </MetricsCard>
  )
}
