import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  GridItem,
  Heading,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react'
import {
  Form,
  FormLayout,
  UseFormReturn,
  useLocalStorage,
  useSnackbar,
} from '@saas-ui/react'
import { FiZap, FiTrash, FiPlus } from 'react-icons/fi'

import {
  Page,
  PageBody,
  PageHeader,
  SectionBody,
  SectionHeader,
  Section,
  SectionDescription,
} from '@saas-ui-pro/react'
import { AppLoader, Breadcrumbs } from '@ui/lib'

import { usePath, useProjectPath } from '@app/features/core/hooks/use-path'

import {
  CREATE_ENVIRONMENT_PG_DATASOURCE,
  CHECK_DATASOURCE,
  DELETE_DATASOURCE,
} from '@api/client'
import { z } from 'zod'
import { RelativeTime } from '@app/i18n'
import React, { useEffect, useRef } from 'react'
import EnvironmentTenantsListing from './EnvironmentTenantsListing'
import EnvironmentMDEListing from './EnvironmentMDEListing'
import { datasourceLabel } from './helper'
import { useLazyQuery, useMutation } from '@apollo/client'
import { SetupPgTunnel } from './setup-pgtunnel'
import { Editor } from '@monaco-editor/react'
import { EnvironmentSelect } from '@app/features/tenants/components/datasources/environment-select'
import {
  Datasource,
  Environment,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'
import { useRouter } from '@app/nextjs'

interface EnvironmentPageProps {
  projectSlug: string
  environmentSlug: string
}
export function EnvironmentPage({
  projectSlug,
  environmentSlug,
}: EnvironmentPageProps) {
  const router = useRouter()
  const { project, invalidate: refetch } = useCurrentUser()
  const env = project?.environments.find((e) => e.slug === environmentSlug)

  const projectsPath = usePath('/projects')
  const environmentsBasePath = useProjectPath('/environments')

  const breadcrumbs = (
    <Breadcrumbs
      items={[
        { href: projectsPath, title: 'Projects' },
        { title: project?.name },
        {
          href: environmentsBasePath,
          title: 'Environments',
        },
        {
          title: (
            <EnvironmentSelect
              name="environment"
              variant={'outline'}
              environments={project?.environments ?? []}
              selectedEnvironment={env ?? null}
              onSelectedEnvironmentChange={(e) => {
                router.push(`${environmentsBasePath}/${e?.slug}`)
              }}
            />
          ),
        },
      ]}
    />
  )

  const [selectedDatasource, setSelectedDatasource] =
    React.useState<Datasource | null>(null)

  React.useEffect(() => {
    if (!selectedDatasource && env?.datasources?.length) {
      setSelectedDatasource(env.datasources[0])
    }
  }, [env?.datasources, selectedDatasource])

  if (!env) {
    return <AppLoader />
  }

  return (
    <Page>
      <PageHeader title={breadcrumbs} />
      <PageBody contentWidth="full">
        <Flex direction="column" gap={4}>
          <GridItem colSpan={{ base: 1, lg: 2 }}>
            <DatasourceSetup
              environment={env}
              selectedDatasource={selectedDatasource}
              onDatasourceSelected={setSelectedDatasource}
              refetch={refetch}
            />
          </GridItem>
          {env.datasources.length > 0 && (
            <GridItem>
              <EnvironmentTenantsListing
                environment={env}
                selectedDatasource={selectedDatasource}
                refetch={refetch}
              />
              <EnvironmentMDEListing
                environment={env}
                selectedDatasource={selectedDatasource}
                refetch={refetch}
              />
            </GridItem>
          )}
        </Flex>
      </PageBody>
    </Page>
  )
}

interface DatasourceSetupProps {
  environment: Environment
  selectedDatasource: Datasource | null
  onDatasourceSelected: (datasource: Datasource) => void
  refetch: () => void
}

export const createDatasourceSchema = z.object({
  connectionString: z
    .string()
    .min(4)
    .describe('Connection String')
    .regex(/^postgresql:\/\/schemamap.*:.*@.*:.*\/.*$/, {
      message: 'Connection string must start with postgresql://schemamap',
    }),
})

export type CreateDatasourceFormInput = z.infer<typeof createDatasourceSchema>

export const defaultPgUrl = (port: number) => {
  return `postgresql://schemamap:schemamap@pgtunnel.eu.schemamap.io:${port}/postgres`
}

const DatasourceSetup = ({
  environment,
  refetch,
  selectedDatasource,
  onDatasourceSelected,
}: DatasourceSetupProps) => {
  const snackbar = useSnackbar()

  // Used to store the last initial schemamap sql, editable by the user
  const [lastSchemamapSql, setLastSchemamapSql] = useLocalStorage<
    string | undefined
  >(`env.${environment.id}.schemamap-initial-sql`, undefined)

  const [createDS, { loading: isCreatingDS }] = useMutation(
    CREATE_ENVIRONMENT_PG_DATASOURCE,
    {
      onCompleted: (data) => {
        refetch()
        setLastSchemamapSql(
          data?.createEnvironmentPgDatasource?.initialSchemamapSql,
        )
        snackbar.success(`Datasource created`)
      },
      onError: (error) => {
        snackbar.error('Failed to create datasource')
      },
    },
  )

  const [checkDS, { loading: isCheckingDS }] = useLazyQuery(CHECK_DATASOURCE, {
    onCompleted: (data) => {
      if (data?.checkDatasource?.connected) {
        snackbar.success('Datasource can be connected to!')
        refetch()
      } else {
        snackbar.error('Failed to connect to datasource')
      }
    },
    onError: (error) => {
      refetch()
      snackbar.error('Failed to connect to datasource')
    },
  })

  const [deleteDS, { loading: isDeletingDS }] = useMutation(DELETE_DATASOURCE, {
    onCompleted: (data) => {
      refetch()
      snackbar.success('Datasource deleted!')
    },
    onError: (data) => {
      refetch()
      snackbar.error('Failed to delete datasource')
    },
  })

  const formRef = useRef<UseFormReturn<CreateDatasourceFormInput>>(null)

  useEffect(() => {
    if (environment.pgtunnelPorts?.[0]?.port) {
      formRef.current?.setValue(
        'connectionString',
        defaultPgUrl(environment.pgtunnelPorts?.[0]?.port),
      )
    }
  }, [environment.pgtunnelPorts])

  const hasDatasources = environment.datasources.length > 0

  const [showCreate, setShowCreate] = React.useState<boolean>(false)

  return (
    <Section variant="annotated">
      <SectionHeader
        title="Datasource Setup"
        maxW={'sm'}
        description={
          <>
            <SectionDescription>
              Connect your Postgres database to Schemamap.io.
            </SectionDescription>
            <SectionDescription mt={2}>
              Add multiple datasources per environment to allow using row-level
              security policies or per-tenant databases.
            </SectionDescription>
          </>
        }
      />
      <SectionBody>
        {environment.datasources.map((datasource) => {
          return (
            <Card key={datasource.id} mb={6}>
              <CardHeader>
                <Heading
                  size={'md'}
                  cursor={'pointer'}
                  onClick={() => {
                    onDatasourceSelected(datasource)
                  }}
                >
                  {datasourceLabel(datasource)}
                  {selectedDatasource?.id === datasource.id && (
                    <Badge
                      colorScheme={'green'}
                      size={'lg'}
                      aria-label="Selected"
                      ml={2}
                    >
                      Selected
                    </Badge>
                  )}
                </Heading>
                <StatGroup mt={4} maxW="600px">
                  <Stat>
                    <StatLabel>Last Connection Attempted</StatLabel>
                    <StatNumber>
                      {!datasource.lastConnectionAttemptAt ? (
                        'Never'
                      ) : (
                        <RelativeTime
                          date={new Date(datasource.lastConnectionAttemptAt)}
                        />
                      )}
                    </StatNumber>
                  </Stat>

                  <Stat>
                    <StatLabel>Last Successful Connection Attempt</StatLabel>
                    <StatNumber>
                      {!datasource.lastSuccessfulConnectionAttemptAt ? (
                        'Never'
                      ) : (
                        <RelativeTime
                          date={
                            new Date(
                              datasource.lastSuccessfulConnectionAttemptAt,
                            )
                          }
                        />
                      )}
                    </StatNumber>
                  </Stat>
                </StatGroup>
              </CardHeader>
              <CardBody>
                <ButtonGroup isDisabled={isCheckingDS || isDeletingDS}>
                  <Button
                    colorScheme="primary"
                    variant="solid"
                    leftIcon={<FiZap />}
                    onClick={() => {
                      checkDS({
                        variables: { datasourceId: datasource.id?.toString() },
                      })
                    }}
                  >
                    Check connection
                  </Button>
                  <Button
                    colorScheme="red"
                    variant="solid"
                    leftIcon={<FiTrash />}
                    onClick={() => {
                      deleteDS({ variables: { datasourceId: datasource.id } })
                    }}
                  >
                    Delete datasource
                  </Button>
                </ButtonGroup>
              </CardBody>
            </Card>
          )
        })}
        {hasDatasources && !showCreate && (
          <Button
            colorScheme="green"
            variant="solid"
            leftIcon={<FiPlus />}
            onClick={() => {
              setShowCreate(true)
            }}
            mb={6}
          >
            Create another Datasource
          </Button>
        )}
        {hasDatasources && lastSchemamapSql && (
          <Card>
            <CardHeader>
              <Heading size={'md'}>Schemamap.io SDK configuration</Heading>
              <Text textColor={'muted'} mt={2}>
                The following SDK setup SQL was inferred, to get you started.
              </Text>
              <Text textColor={'muted'} mt={2}>
                Modify it and apply it with your regular DB migrations,
                preferably in a repeatable migration, as all the operations are
                idempotent.
              </Text>
            </CardHeader>
            <CardBody>
              <Editor
                height={450}
                defaultLanguage="pgsql"
                beforeMount={(monaco) => {
                  setTimeout(() => {
                    monaco.editor.setTheme('vs-dark')
                  }, 1)
                }}
                value={lastSchemamapSql}
                onChange={setLastSchemamapSql}
                options={{
                  theme: 'vs-dark',
                  minimap: { enabled: false },
                  autoDetectHighContrast: false,
                  lineNumbers: 'on',
                  wordWrap: 'on',
                  scrollBeyondLastLine: false,
                  automaticLayout: true,
                }}
              />
            </CardBody>
          </Card>
        )}
        {(!hasDatasources || showCreate) && (
          <Box maxW={'1400'}>
            <SetupPgTunnel environment={environment} />
            <Form
              formRef={formRef}
              schema={createDatasourceSchema}
              defaultValues={{
                connectionString: defaultPgUrl(
                  environment.pgtunnelPorts?.[0]?.port || 5432,
                ),
              }}
              onSubmit={(data) => {
                createDS({
                  variables: {
                    connectionString: data?.connectionString,
                    environmentId: environment.id.toString(),
                  },
                })
              }}
            >
              {({ Field }) => (
                <Card maxW={'container.lg'}>
                  <CardBody>
                    <FormLayout>
                      <Field
                        name="connectionString"
                        label="Connection string"
                      />
                    </FormLayout>
                  </CardBody>
                  <CardFooter>
                    <Button
                      variant="solid"
                      colorScheme="primary"
                      type="submit"
                      isLoading={isCreatingDS}
                    >
                      Connect
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </Form>
          </Box>
        )}
      </SectionBody>
    </Section>
  )
}
import { createPage } from '@app/nextjs'

import { SchemaOverviewPage } from '@app/features/tenants/pages/schema-overview'

export default createPage({
  title: 'Schema Overview',
  renderComponent: () => {
    return <SchemaOverviewPage />
  },
})
