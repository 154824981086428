import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Container,
  HStack,
  IconButton,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Tooltip,
  VStack,
  useClipboard,
} from '@chakra-ui/react'

import { useMutation, useQuery } from '@apollo/client'

import { Page, Toolbar, PageBody, PageHeader } from '@saas-ui-pro/react'

import {
  Metric,
  MetricProps,
} from '@app/features/tenants/components/metrics/metric'
import {
  Datasource,
  Environment,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'
import {
  SegmentedControl,
  DateRangePicker,
  getRangeValue,
  DateRangePresets,
  DateRange,
  getRangeDiff,
  LinkButton,
} from '@ui/lib'
import { useEffect, useState, useMemo } from 'react'
import { percentDiff } from '../dashboard'
import { MetricsCard } from '@app/features/tenants/components/metrics/metrics-card'
import { useRouter } from '@app/nextjs'
import {
  EnvironmentDatasourceSelect,
  EnvironmentSelect,
} from '@app/features/tenants/components/datasources/environment-select'
import {
  FiBarChart,
  FiBookOpen,
  FiCopy,
  FiDownload,
  FiMap,
  FiRefreshCw,
} from 'react-icons/fi'
import {
  RENDER_SCHEMA_GRAPH_D2,
  SCHEMA_METRICS,
  SYNC_DATASOURCE_SMO,
} from '@api/client'
import { useSnackbar } from '@saas-ui/react'
import { SvgPreview } from '../postgres/review'
import { TableClusters } from './table-clusters'

type SchemaGridProps = {
  dateRange: DateRange
}

const SchemaGrid = ({ dateRange }: SchemaGridProps) => {
  const router = useRouter()
  const snackbar = useSnackbar()
  const { project } = useCurrentUser()
  const { setValue: setD2TextCopy, onCopy, hasCopied } = useClipboard('')

  const [selectedEnvironment, setSelectedEnvironment] =
    useState<Environment | null>(null)

  const [selectedDatasource, setSelectedDatasource] =
    useState<Datasource | null>(null)

  const [
    renderSchemamapSvg,
    { data: schemaMapResult, loading: isLoadingSchemaMap },
  ] = useMutation(RENDER_SCHEMA_GRAPH_D2, {})

  const [
    calculateSchemaMetrics,
    { data: metricsData, loading: isLoadingSchemaMetrics },
  ] = useMutation(SCHEMA_METRICS, {})

  const metrics: MetricProps[] = useMemo(() => {
    if (!metricsData?.schemaMetrics) return []

    const {
      tableCountCurrent,
      tableCountPrevious,
      columnCountCurrent,
      columnCountPrevious,
      schemaGrowthCurrent,
      schemaGrowthPrevious,
      schemaBreakageCurrent,
      schemaBreakagePrevious,
      constraintPerColumnPercentageCurrent,
      constraintPerColumnPercentagePrevious,
    } = metricsData.schemaMetrics

    return [
      {
        label: 'Tables',
        value: tableCountCurrent,
        previousValue: tableCountPrevious,
        isIncreasePositive: true,
      },
      {
        label: 'Columns',
        value: columnCountCurrent,
        previousValue: columnCountPrevious,
        isIncreasePositive: true,
      },
      {
        label: 'Schema Growth',
        value: schemaGrowthCurrent,
        previousValue: schemaGrowthPrevious,
        isIncreasePositive: true,
      },
      {
        label: 'Schema Breakage',
        value: schemaBreakageCurrent,
        previousValue: schemaBreakagePrevious,
        isIncreasePositive: false,
      },
      {
        label: 'Constraints per Column %',
        value: constraintPerColumnPercentageCurrent,
        previousValue: constraintPerColumnPercentagePrevious,
        isIncreasePositive: true,
      },
    ].map(
      (metric): MetricProps => ({
        ...metric,
        value: metric.value.toString(),
        previousValue: metric.previousValue.toString(),
        change: percentDiff(metric.value, metric.previousValue),
        id: metric.label.toLowerCase().replaceAll(' ', '-'),
      }),
    )
  }, [metricsData])

  const renderSvg = () => {
    if (!selectedDatasource?.id) return null

    snackbar.promise(
      renderSchemamapSvg({
        variables: {
          datasourceId: selectedDatasource.id,
        },
      }),
      {
        loading: 'Rendering Schema Map...',
        success: 'Rendered!',
        error: (e) => 'Failed to generate Schema Map: ' + e.message,
      },
    )
  }

  const [resyncSmo, { loading: isLoadingSmoResync }] =
    useMutation(SYNC_DATASOURCE_SMO)

  const refreshSchema = () => {
    if (!selectedDatasource?.id) return

    snackbar.promise(
      resyncSmo({
        variables: {
          datasourceId: selectedDatasource?.id,
          refresh: true,
        },
      }),
      {
        loading: 'Refreshing Schema Overview...',
        success: 'Refreshed!',
        error: (e) => 'Failed to refresh Schema Map: ' + e.message,
      },
    )
  }

  const result = schemaMapResult?.renderDatasourceSchemaMap

  const { d2Svg, d2Text } = result ?? {}

  useEffect(() => {
    if (d2Text) setD2TextCopy(d2Text)
  }, [d2Text, setD2TextCopy])

  if (d2Svg)
    return (
      <SvgPreview svg={d2Svg}>
        <VStack
          position={'absolute'}
          alignItems={'stretch'}
          bottom={2}
          right={2}
          spacing={1}
        >
          {d2Text && (
            <Button
              leftIcon={<FiCopy />}
              onClick={() => {
                onCopy()
                snackbar.info('Copied D2 text to clipboard')
              }}
            >
              Copy D2 text
            </Button>
          )}
          <Button
            leftIcon={<FiDownload />}
            onClick={() => {
              const blob = new Blob([d2Svg], { type: 'image/svg+xml' })
              const url = URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = 'schema-map.svg'
              a.click()
              URL.revokeObjectURL(url)
            }}
          >
            Download SVG
          </Button>
          <Button leftIcon={<FiRefreshCw />} onClick={renderSvg}>
            Re-render
          </Button>
        </VStack>
      </SvgPreview>
    )

  return (
    <>
      <Grid
        m={'0 auto'}
        p={8}
        templateColumns={['repeat(1, 1fr)', null, null, 'repeat(2, 1fr)']}
        gridAutoColumns="fr1"
        gap={{ base: 4, xl: 8 }}
        maxW={'container.2xl'}
      >
        <GridItem colSpan={{ base: 1, lg: 2 }} maxW="100vw">
          <Tabs variant="unstyled" tabIndex={0}>
            <TabList
              overflow="hidden"
              borderTopRadius="md"
              display="flex"
              flexWrap="wrap"
            >
              {metrics.map((metric) => (
                <Tab
                  key={metric.id}
                  id={metric.id}
                  alignItems="stretch"
                  justifyContent="stretch"
                  flex={{ base: '0 0 50%', lg: '1 0 auto' }}
                  height="auto"
                  textAlign="left"
                  borderBottomWidth="1px"
                  borderRightWidth="1px"
                  _hover={{
                    bg: 'whiteAlpha.100',
                    _dark: {
                      bg: 'whiteAlpha.100',
                    },
                  }}
                  _selected={{
                    borderBottomWidth: '2px',
                    borderBottomColor: 'primary.500',
                    display: 'flex',
                  }}
                  _last={{
                    borderRightWidth: '0',
                  }}
                >
                  <Metric {...metric} />
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {metrics.map((metric) => (
                <TabPanel key={metric.id} pt="8"></TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </GridItem>

        <GridItem>
          <MetricsCard title="Database-level introspection">
            <VStack alignItems={'flex-start'}>
              <HStack>
                <EnvironmentSelect
                  name="environment"
                  environments={
                    project?.environments.filter(
                      (e) => e.datasources.length > 0,
                    ) ?? []
                  }
                  selectedEnvironment={selectedEnvironment}
                  onSelectedEnvironmentChange={(e) => {
                    const newEnv = project?.environments.find(
                      (pe) => pe.id == e?.id,
                    )
                    setSelectedEnvironment(newEnv ?? null)
                    setSelectedDatasource(newEnv?.datasources[0] ?? null)
                  }}
                />
                <EnvironmentDatasourceSelect
                  name="datasource"
                  isDisabled={!selectedEnvironment}
                  datasources={selectedEnvironment?.datasources ?? []}
                  selectedDatasource={selectedDatasource}
                  onSelectedDatasourceChange={setSelectedDatasource}
                />
                {selectedDatasource && (
                  <Tooltip label="Refresh schema">
                    <IconButton
                      aria-label="Refresh schema"
                      icon={<FiRefreshCw />}
                      isLoading={isLoadingSmoResync}
                      onClick={refreshSchema}
                    />
                  </Tooltip>
                )}
              </HStack>
              <Divider my={'1rem'} />

              <ButtonGroup isDisabled={!selectedDatasource?.id}>
                <Button
                  leftIcon={<FiMap />}
                  isLoading={isLoadingSchemaMap}
                  onClick={renderSvg}
                >
                  View Schema Map
                </Button>
                <Button
                  leftIcon={<FiBarChart />}
                  isLoading={isLoadingSchemaMetrics}
                  onClick={() => {
                    if (!selectedDatasource?.id) return

                    calculateSchemaMetrics({
                      variables: {
                        datasourceId: selectedDatasource?.id,
                        start: dateRange.start.toString(),
                        end: dateRange.end.toString(),
                      },
                    })
                  }}
                >
                  Calculate Schema Metrics
                </Button>
                <Link
                  as={Button}
                  leftIcon={<FiBookOpen />}
                  variant={'primary'}
                  href={`${router.asPath}/columns`}
                  isDisabled
                  title={'Coming soon...'}
                >
                  Schema Recommendations
                </Link>
              </ButtonGroup>
            </VStack>
          </MetricsCard>
        </GridItem>
        <GridItem>
          <MetricsCard title="Project-level introspection">
            <VStack alignItems={'flex-start'}>
              <LinkButton
                variant={'secondary'}
                href={`${router.asPath}/columns`}
              >
                Column overview
              </LinkButton>
            </VStack>
          </MetricsCard>
        </GridItem>
      </Grid>
      {metricsData?.schemaMetrics?.tableClusters && (
        <Container maxW="container.2xl" px={8}>
          <TableClusters
            tableClusters={metricsData.schemaMetrics.tableClusters}
          />
        </Container>
      )}
    </>
  )
}

export function SchemaOverviewPage() {
  const { isLoading, project } = useCurrentUser()

  const [range, setRange] = useState('30d')
  const [dateRange, setDateRange] = useState(getRangeValue('30d'))
  const onPresetChange = (preset: string) => {
    if (preset !== 'custom') {
      setDateRange(getRangeValue(preset as DateRangePresets))
    }
    setRange(preset)
  }

  const onRangeChange = (range: DateRange) => {
    const diff = getRangeDiff(range)
    if ([1, 3, 7, 30].includes(diff)) {
      setRange(`${diff}`)
    } else {
      setRange('custom')
    }

    setDateRange(range)
  }

  const footer = (
    <Toolbar
      justifyContent="flex-start"
      alignItems="center"
      variant="secondary"
      size="sm"
    >
      <SegmentedControl
        size="sm"
        segments={[
          {
            id: '1d',
            label: '1d',
          },
          {
            id: '3d',
            label: '3d',
          },
          {
            id: '7d',
            label: '7d',
          },
          { id: '30d', label: '30d' },
          { id: 'custom', label: 'Custom' },
        ]}
        value={range}
        onChange={onPresetChange}
      />
      <DateRangePicker value={dateRange} onChange={onRangeChange} />
    </Toolbar>
  )

  return (
    <Page isLoading={isLoading} key={project?.id}>
      <PageHeader
        title={`Schema Overview - ${project?.name}`}
        footer={footer}
      />
      <PageBody bg="page-body-bg-subtle" p={0} contentWidth={'full'}>
        <SchemaGrid dateRange={dateRange} />
      </PageBody>
    </Page>
  )
}
